import { VIEW_ADMIN_CENTER_PERMISSION } from 'config/constants';
import { rsaUser } from 'data/store/authStore';
import { selector } from 'recoil';

/**
 * Whether or not the user has permission to view the Admin Center
 */
export const rsaHasViewAdminPermission = selector<boolean>({
  key: 'rsaHasViewAdminPermission',
  get: ({ get }) => {
    const user = get(rsaUser);

    return VIEW_ADMIN_CENTER_PERMISSION.some((permission) => user?.permissions?.includes(permission));
  },
});
