import React from 'react';
import Link from 'componentLibrary/Link';
import { rsaIsFullAuth, rsaIsFullAuthFrozen } from 'data';
import { History } from 'history';
import useLogout from 'hooks/useLogout';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import MenuItem from '@mui/material/MenuItem';

import styles from '../UserMenuItems.styles';

const LogoutButton = ({ setProfileMenuAnchorEl }: { setProfileMenuAnchorEl: React.Dispatch<Element> }) => {
  const isFullAuth = useRecoilValue(rsaIsFullAuth);
  const isFullAuthFrozen = useRecoilValue(rsaIsFullAuthFrozen);
  const logoutUser = useLogout({});

  const history: History = useHistory();

  const handleProfilePopoverClose = () => setProfileMenuAnchorEl(null);

  const onIconClick = () => {
    if (isFullAuth) {
      handleProfilePopoverClose();

      logoutUser(null);
    } else {
      history.push('/login');
    }
  };

  return (
    <MenuItem key='menu-login-logout' onClick={onIconClick} data-cy='menu-login-logout'>
      <Link to='/#' sx={styles.profilePopoverItem}>
        {isFullAuth || isFullAuthFrozen ? 'Logout' : 'Login'}
      </Link>
    </MenuItem>
  );
};

export default LogoutButton;
