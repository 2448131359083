import { AxiosInstance } from 'axios';
import { authApi, publicApi } from 'config/api';
import { TILES_URL } from 'config/base';
import _isEmpty from 'lodash/isEmpty';
import { MapLayerConfig, MapTilesMetadata } from 'types';

export default class MapLayerConfigApi {
  api: AxiosInstance;

  constructor() {
    this.init();
  }

  async init(): Promise<void> {
    const api = await authApi();
    this.api = api;
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.api = axiosInstance;
  }

  /**
   * @description Fetches the map layer configs available to this user
   */
  async apiFetchMapLayerConfigs(): Promise<MapLayerConfig[]> {
    try {
      const { data } = await this.api.get(`/maplayerconfig`);
      const mapLayerConfigs = (data as MapLayerConfig[]).filter((config) => !_isEmpty(config.config));

      return mapLayerConfigs;
    } catch (e) {
      console.error('[Pano] API Error MapLayerConfig', e);

      return [];
    }
  }

  /**
   * @description Fetches the map layer config by Id
   * - Used to set the signed cookie for that map layer
   * @returns the Time To Live, to know when to extend the cookie
   */
  async apiGetMapLayerSignedCookie(mapLayerConfigId: MapLayerConfig['id']) {
    try {
      const { headers } = await this.api.get(`/maplayerconfig/${mapLayerConfigId}`, { withCredentials: true });

      return headers['x-ttl'];
    } catch (e) {
      console.error('[Pano] API Error MapLayerConfig', e);

      return null;
    }
  }

  /**
   * @description Fetches the metadata for the given map layer
   * - Used to determine which zoom levels to request tiles for
   * @returns the Time To Live, to know when to extend the cookie
   */
  async apiGetMapLayerMetadata(collectionName: MapLayerConfig['collectionName']) {
    try {
      const res = await publicApi.get(`${TILES_URL}/geo/${collectionName}/metadata.json`, {
        withCredentials: true,
      });

      return res.data as MapTilesMetadata;
    } catch (e) {
      console.error('[Pano] API Error MapLayerConfig', e);

      throw new Error('[Pano] API Error MapLayerConfig');
    }
  }
}
