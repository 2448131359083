import { atom, RecoilState, selector, selectorFamily } from 'recoil';
import { LonLat, MapLayerPopupInfo, MapLayerPopupTitles } from 'types';

import { rsmDynamicLayerPrioritization, rsmMapLayerConfigById } from '../mapLayerConfig';

import { getDisplayedInfoWithoutGeometry, getGeometryFromSelectedLayer } from './mapLayerPopups.helpers';

/**
 * This tracks the items that are hovered for each layer
 */
export const rsmPopupTitles: RecoilState<MapLayerPopupTitles> = atom<MapLayerPopupTitles>({
  key: 'rsmPopupTitles',
  default: {
    pgeFacilities: 'PGE Facilities',
    pgeOverheadMainlines: 'PGE Overhead Mainline',
    pgeOverheadTaplines: 'PGE Overhead Tapline',
    pgeUndergroundMainlines: 'PGE Underground Mainline',
    pgeUndergroundTaplines: 'PGE Underground Tapline',
    gtfaDispatchZones: 'Dispatch Zones',
    gtfaFocMapGrid: 'FOC Map Index',
    gtfaCfaMapGrid: 'Spatial Vision (Vic)',
    gtfaCfsMapGrid: 'CFS Map Index',
    forico50k: '50K Mapsheet',
    tfsBrigade: 'TFS Brigade Boundaries',
  },
});

/**
 * This tracks the position where the user last clicked
 */
export const rsmMapClickLatLon: RecoilState<LonLat> = atom<LonLat>({
  key: 'rsmMapClickLatLon',
  default: {
    lat: 0,
    lon: 0,
  },
});

/**
 * The incident id and the id of the camera associated with the incident
 * @note cameraId is null if the incident marker was clicked rather
 * than the incident bar which is associated with a camera
 */
export const rsmClickedIncidentInfo = atom<{
  incidentId: number;
  cameraId: number;
}>({
  key: 'rsmClickedIncidentInfo',
  default: {
    incidentId: null,
    cameraId: null,
  },
});

/**
 * This tracks the currently selected item for each layer
 */
export const rsmSelectedLayers: RecoilState<MapLayerPopupInfo> = atom<MapLayerPopupInfo>({
  key: 'rsmSelectedLayer',
  default: {
    pgeFacilities: undefined,
    pgeOverheadMainlines: undefined,
    pgeOverheadTaplines: undefined,
    pgeUndergroundMainlines: undefined,
    pgeUndergroundTaplines: undefined,
    gtfaDispatchZones: undefined,
    gtfaFocMapGrid: undefined,
    gtfaCfaMapGrid: undefined,
    gtfaCfsMapGrid: undefined,
    forico50k: undefined,
    tfsBrigade: undefined,
  },
});

/**
 * @param layerName - The name of the layer we want to get
 * @returns a setter and the value for what feature is selected for the given layer name
 */
export const rsmSelectedLayerByName: (
  param: keyof MapLayerPopupInfo,
) => RecoilState<MapLayerPopupInfo[keyof MapLayerPopupInfo]> = selectorFamily<
  MapLayerPopupInfo[keyof MapLayerPopupInfo],
  keyof MapLayerPopupInfo
>({
  key: 'rsmSelectedLayerByName',
  get:
    (param) =>
    ({ get }) => {
      const hoveredLayers: MapLayerPopupInfo = get(rsmSelectedLayers);

      return hoveredLayers[param];
    },
  set:
    (param) =>
    ({ set }, newValue) => {
      set(
        rsmSelectedLayers,
        (prevObject) =>
          ({
            ...prevObject,
            [param]: newValue,
          } as MapLayerPopupInfo),
      );
    },
});

/**
 * We currently only show information for a single asset in our hover and select popups
 * This manages the prioritization for which data is put in the popup
 * - Dynamically loaded layers get top prioritization, and they're prioritized by popupInfoPriority
 */
export const rsmLayerPrioritization = selector({
  key: 'rsmLayerPrioritization',
  get: ({ get }) => {
    const popupTitles = get(rsmPopupTitles);
    const dynamicLayerPrioritization = get(rsmDynamicLayerPrioritization);

    const keysArray = Array.from(Object.keys(popupTitles));

    return [
      ...new Set([
        ...dynamicLayerPrioritization,
        'pgeFacilities',
        'pgeOverheadMainlines',
        'pgeOverheadTaplines',
        'pgeUndergroundMainlines',
        'pgeUndergroundTaplines',
        'gtfaDispatchZones',
        'gtfaFocMapGrid',
        'tfsBrigade',
        'forico50k',
        ...keysArray,
      ]),
    ] as (keyof MapLayerPopupInfo)[];
  },
});

/**
 * @description Gets the info and title for the Map Popup for the selected layer
 */
export const rsmSelectedLayerPopupContent = selector({
  key: 'rsmSelectedLayerPopupContent',
  get: ({ get }) => {
    const selectedLayers = get(rsmSelectedLayers);
    const popupTitles = get(rsmPopupTitles);
    const layerPrioritzation = get(rsmLayerPrioritization);

    const highestPriorityActiveLayerName = layerPrioritzation
      .find((layerName) => !!selectedLayers[layerName])
      ?.toString();

    const mapConfig = get(rsmMapLayerConfigById(highestPriorityActiveLayerName));

    if (highestPriorityActiveLayerName) {
      const properties = mapConfig
        ? mapConfig.config.displayInfo.properties?.reduce((infoObj, property) => {
            const value = selectedLayers[highestPriorityActiveLayerName][property.property];
            if (value) {
              return {
                ...infoObj,
                [property.value]: value,
              };
            }

            return infoObj;
          }, {})
        : selectedLayers[highestPriorityActiveLayerName];

      const title = mapConfig
        ? mapConfig.config.displayInfo.title
        : popupTitles[highestPriorityActiveLayerName as keyof MapLayerPopupTitles];

      const info = getDisplayedInfoWithoutGeometry(properties);

      return {
        title,
        info,
      };
    }

    return {
      title: '',
      info: undefined,
    };
  },
});

/**
 * @description Gets the geometry of the feature from the selected layer
 */
export const rsmSelectedLayerGeometry = selector({
  key: 'rsmSelectedLayerGeometry',
  get: ({ get }) => {
    const selectedLayers = get(rsmSelectedLayers);
    const layerPrioritzation = get(rsmLayerPrioritization);

    const highestPriorityActiveLayerName = layerPrioritzation
      .find((layerName) => !!selectedLayers[layerName])
      ?.toString();

    if (highestPriorityActiveLayerName) {
      const geometry = getGeometryFromSelectedLayer(selectedLayers[highestPriorityActiveLayerName]);

      return geometry;
    }

    return null;
  },
});
