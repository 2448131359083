import React from 'react';
import { BooleanFeatureFlag, BooleanVariant } from 'componentLibrary/EvaluatedFeatureFlag';
import Link from 'componentLibrary/Link';
import PICWorkflowFlag, { PICFeature } from 'components/PICWorkflow/PICWorkflowFlag/PICWorkflowFlag';
import { rsaHasViewAdminPermission, rsaIsFullAuth, rsaIsFullAuthFrozen, rsaUserEmail } from 'data';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import LogoutButton from './LogoutButton';
import PICLogoutButton from './PICLogoutButton';
import styles from './UserMenuItems.styles';

const legalPages: [string, string] = ['/terms', '/privacy'];

const UserMenuItems = ({ setProfileMenuAnchorEl }: { setProfileMenuAnchorEl: React.Dispatch<Element> }) => {
  const isFullAuth = useRecoilValue(rsaIsFullAuth);
  const isFullAuthFrozen = useRecoilValue(rsaIsFullAuthFrozen);
  const userEmail = useRecoilValue(rsaUserEmail);
  const location = useLocation();
  const hasViewAdminPermission = useRecoilValue(rsaHasViewAdminPermission);

  const isLegalPage = !legalPages.includes(location.pathname);

  return (
    <>
      {(isFullAuth || isFullAuthFrozen) && (
        <>
          <Box sx={styles.menuHeader} data-cy='menu-header'>
            Account
            <Typography sx={styles.email} variant='body2'>
              {userEmail}
            </Typography>
          </Box>
          <BooleanFeatureFlag flagKey='FE-Show-Ops-Center-View' defaultValue={false}>
            <BooleanVariant condition={true}>
              <MenuItem key='menu-ocv' data-cy='menu-ocv'>
                <Link to='/ocv/incidents' sx={styles.profilePopoverItem}>
                  Operations Center View
                </Link>
              </MenuItem>
            </BooleanVariant>
          </BooleanFeatureFlag>
          <MenuItem key='menu-settings' data-cy='menu-settings'>
            <Link to='/account/settings' sx={styles.profilePopoverItem}>
              Account Settings
            </Link>
          </MenuItem>
        </>
      )}
      {hasViewAdminPermission && (
        <MenuItem key='menu-admin' data-cy='menu-admin'>
          <Link to='/admin' sx={styles.profilePopoverItem}>
            Admin Center
          </Link>
        </MenuItem>
      )}
      <PICWorkflowFlag>
        <PICFeature enabled>
          <PICLogoutButton setProfileMenuAnchorEl={setProfileMenuAnchorEl} />
        </PICFeature>
        <PICFeature enabled={false}>
          <LogoutButton setProfileMenuAnchorEl={setProfileMenuAnchorEl} />
        </PICFeature>
      </PICWorkflowFlag>
      {isLegalPage && (
        <>
          <Divider key='menu-divider' light sx={styles.divider} />
          <MenuItem key='menu-tos' data-cy='menu-tos'>
            <Link to='/terms' sx={styles.profilePopoverItem} target='_blank'>
              Terms of Service
            </Link>
          </MenuItem>
          <MenuItem key='menu-privacy' data-cy='menu-privacy'>
            <Link to='/privacy' sx={styles.profilePopoverItem} target='_blank'>
              Privacy Policy
            </Link>
          </MenuItem>
        </>
      )}
    </>
  );
};

export default UserMenuItems;
