import { HEADER_HEIGHT } from 'config/constants';

import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  adminHeader: (theme: Theme) => ({
    backgroundColor: theme.palette.primary.dark,
    height: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
  }),
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: (theme: Theme) => ({
    paddingRight: theme.spacing(2),
  }),
};

export default styles;
