import { USER_MAP_LAYERS_VISIBILITY, USER_WEATHER_MAP_LAYER_SELECTION } from 'config/base';
import {
  rsfFeatureFlags,
  rsiIncidentLonLatById,
  rsmFetchMapLayerConfigStatus,
  rsmNearByAssetEligibleLocations,
} from 'data';
import { localStorageEffect } from 'data/effect';
import { MapApis } from 'data/proxyApi';
import {
  rsaIsMetric,
  rsaIsUserOrgPge,
  rsaNearbyAssetRadiusInMeters,
  rsaNearbyAssetRadiusInMiles,
  rsaOrgWithPowerLines,
} from 'data/store/authStore';
import { getLocalBooleanFlagOverride } from 'hooks/useFeatureFlags/useFeatureFlag.helpers';
import _sortBy from 'lodash/sortBy';
import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import {
  ApiCallStatus,
  Incident,
  LayerVisibility,
  LonLat,
  MapAssetDataSet,
  MapLayerConfig,
  MapTilesMetadata,
  WeatherLayerNames,
  WeatherLayerSelection,
  WeatherMapLayerData,
} from 'types';
import { hasLngLat, metersToKilometers, metersToMiles } from 'utils';
import { UnitFormat, UnitSystem } from 'weatherlayers-gl';

import { rsmMapUnits } from '../mapStore';

export const rsmMapLayersVisibility = atom<LayerVisibility>({
  key: 'rsmMapLayersVisibility',
  default: {
    temperature: false,
    privateForests: false,
    rfsBounds: false,
    pgeFacilities: false,
    pgeOverheadMainlines: false,
    pgeOverheadTaplines: false,
    pgeUndergroundMainlines: false,
    pgeUndergroundTaplines: false,
    gtfaDispatchZones: false,
    gtfaFocMapGrid: false,
    gtfaCfaMapGrid: false,
    gtfaCfsMapGrid: false,
    hvpPlantation: false,
    forico50k: false,
    tfsBrigade: false,
  },
  effects: [localStorageEffect(USER_MAP_LAYERS_VISIBILITY, null)],
});

/** @description Whether or not the map layers menu is open */
export const rsmIsMapLayersMenuOpen = atom<boolean>({
  key: 'rsmIsMapLayersMenuOpen',
  default: false,
});

/** @description Whether or not the map legend menu is open */
export const rsmIsMapLegendMenuOpen = atom<boolean>({
  key: 'rsmIsMapLegendMenuOpen',
  default: false,
});

/** @description Stores which weather map layers are selected */
export const rsmWeatherMapLayerSelection = atom<WeatherLayerSelection>({
  key: 'rsmWeatherMapLayerSelection',
  default: {
    raster: null,
    animation: false,
  },
  effects: [localStorageEffect(USER_WEATHER_MAP_LAYER_SELECTION, null)],
});

/** @description Stores the data from WeatherLayers Cloud */
export const rsmWeatherMapLayerData = atom<WeatherMapLayerData | Record<WeatherLayerNames, never>>({
  key: 'rsmWeatherMapLayerData',
  default: {
    temperature: null,
    humidity: null,
    'wind-speed': null,
    'wind-gust': null,
  },
});

/** @description Get the name of the selected raster layer, if any */
export const rsmSelectedWeatherRasterLayer = selector<WeatherLayerNames>({
  key: 'rsmSelectedWeatherRasterLayer',
  get: ({ get }) => {
    const { raster } = get(rsmWeatherMapLayerSelection);

    return raster;
  },
});

/** @description Get the name of the selected raster layer, if any */
export const rsmSelectedWeatherAnimationLayer = selector<WeatherLayerNames>({
  key: 'rsmSelectedWeatherAnimationLayer',
  get: ({ get }) => {
    const { animation } = get(rsmWeatherMapLayerSelection);

    return animation ? 'wind-speed' : null;
  },
});

/** @description Get an array of unique selected weather layers */
export const rsmSelectedWeatherLayerNames = selector<WeatherLayerNames[]>({
  key: 'rsmSelectedWeatherLayerNames',
  get: ({ get }) => {
    const selectedRasterLayer = get(rsmSelectedWeatherRasterLayer);
    const selectedAnimationLayer = get(rsmSelectedWeatherAnimationLayer);

    const selectedLayers: WeatherLayerNames[] = [];
    if (selectedRasterLayer) {
      selectedLayers.push(selectedRasterLayer);
    }
    if (selectedAnimationLayer && selectedAnimationLayer !== selectedRasterLayer) {
      selectedLayers.push(selectedAnimationLayer);
    }

    return selectedLayers;
  },
});

/** @description Get the name of the visible raster layer if the layer is selected and the data is ready */
export const rsmVisibleRasterLayerName = selector<WeatherLayerNames>({
  key: 'rsmVisibleRasterLayerName',
  get: ({ get }) => {
    const selectedRasterLayer = get(rsmSelectedWeatherRasterLayer);
    const weatherMapLayerData = get(rsmWeatherMapLayerData);
    if (!selectedRasterLayer || !weatherMapLayerData[selectedRasterLayer]) {
      return null;
    }

    return selectedRasterLayer;
  },
});

/** @description Get the name of the visible animation layer if the data is also ready */
export const rsmVisibleAnimationLayerName = selector<WeatherLayerNames>({
  key: 'rsmVisibleAnimationLayerName',
  get: ({ get }) => {
    const selectedAnimationLayer = get(rsmSelectedWeatherAnimationLayer);
    const weatherMapLayerData = get(rsmWeatherMapLayerData);
    if (!selectedAnimationLayer || !weatherMapLayerData[selectedAnimationLayer]) {
      return null;
    }

    return selectedAnimationLayer;
  },
});

/** @description Boolean to indicate whether there is any weather data visible */
export const rsmIsAnyWeatherLayerVisible = selector<boolean>({
  key: 'rsmIsAnyWeatherLayerVisible',
  get: ({ get }) => {
    const visibleRasterLayer = get(rsmVisibleRasterLayerName);
    const visibleAnimationLayer = get(rsmVisibleAnimationLayerName);

    return !!(visibleRasterLayer || visibleAnimationLayer);
  },
});

/** @description Boolean to indicate whether any weather layer is selected but does not have data yet */
export const rsmIsWeatherLayerLoading = selector<boolean>({
  key: 'rsmIsWeatherLayerLoading',
  get: ({ get }) => {
    const selectedRasterLayer = get(rsmSelectedWeatherRasterLayer);
    const selectedAnimationLayer = get(rsmSelectedWeatherAnimationLayer);
    const weatherMapData = get(rsmWeatherMapLayerData);

    if (selectedRasterLayer && !weatherMapData[selectedRasterLayer]) {
      return true;
    } else if (selectedAnimationLayer && !weatherMapData[selectedAnimationLayer]) {
      return true;
    }

    return false;
  },
});

/** @description Gets the unit system to use for WeatherLayers */
export const rsmWeatherLayerUnitSystem = selector<UnitSystem | null>({
  key: 'rsmWeatherLayerUnitSystem',
  get: ({ get }) => {
    const unitSystem = get(rsmMapUnits);

    return unitSystem === UnitSystem.METRIC ? UnitSystem.METRIC_KILOMETERS : unitSystem;
  },
});

/** @description Gets the unit format to use for WeatherLayers */
export const rsmWeatherRasterLayerUnitFormat = selector<UnitFormat | null>({
  key: 'rsmWeatherLayerUnitFormat',
  get: ({ get }) => {
    const layerName = get(rsmWeatherMapLayerSelection).raster;
    const weatherMapLayerData = get(rsmWeatherMapLayerData);

    return weatherMapLayerData[layerName]?.datasetMetadata?.unitFormat || null;
  },
});

/** @description Gets latest delivery time for a layer */
export const rsmGetLatestDeliveryTimeOfLayer = selectorFamily<string, WeatherLayerNames>({
  key: 'rsmGetLatestDeliveryTimeOfLayer',
  get:
    (layerName) =>
    ({ get }) => {
      const weatherMapLayerData = get(rsmWeatherMapLayerData);

      return weatherMapLayerData[layerName]?.imageData?.referenceDatetime;
    },
});

/** @description Get the latest update time of visible weather layers */
export const rsmLastWeatherLayerUpdateTime = selector<Date>({
  key: 'rsmLastWeatherLayerUpdateTime',
  get: ({ get }) => {
    const visibleRasterLayer = get(rsmVisibleRasterLayerName);
    const visibleAnimationLayer = get(rsmVisibleAnimationLayerName);
    const rasterUpdateTime = get(rsmGetLatestDeliveryTimeOfLayer(visibleRasterLayer));
    const animationUpdateTime = get(rsmGetLatestDeliveryTimeOfLayer(visibleAnimationLayer));

    const updateTimes = [];
    if (rasterUpdateTime) {
      updateTimes.push(new Date(rasterUpdateTime).getTime());
    }
    if (animationUpdateTime) {
      updateTimes.push(new Date(animationUpdateTime).getTime());
    }
    if (updateTimes.length === 0) {
      return null;
    }

    return new Date(Math.min(...updateTimes));
  },
});

/** @description Get the last updated time for visible weather layer data to display according to locale */
export const rsmLastUpdateTimeLocalized = selector<string>({
  key: 'rsmLastUpdateTimeLocalized',
  get: ({ get }) => {
    const lastUpdatedTime = get(rsmLastWeatherLayerUpdateTime);
    const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
    const options: Intl.DateTimeFormatOptions = {
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone,
    };

    return lastUpdatedTime?.toLocaleString(locale, options);
  },
});

/**
 * @description - Get/set wether the give map layer is visible
 * - **asset** - A customer's property (e.g. Planation, Power Facility, Powerline, etc)
 */
export const rsmMapLayerVisibilityByName = selectorFamily<boolean | DefaultValue, string>({
  key: 'rsmMapLayerVisibilityByName',
  get:
    (layerName) =>
    ({ get }) => {
      const mapLayers = get(rsmMapLayersVisibility);

      return !!mapLayers[layerName];
    },
  set:
    (layerName) =>
    ({ set }, isVisible) => {
      set(rsmMapLayersVisibility, (prevValues) => ({
        ...prevValues,
        [layerName]: isVisible instanceof DefaultValue ? false : isVisible,
      }));
    },
});

/**
 * @description Returns the map layers eligble for Nearby assets queries
 * - These are the map layers served from our GeoJSON server
 * - Some Map Layers come from mapbox.
 * - **NOTE** - These are restricted to those which the user is eligible to query
 *
 */
export const rsmMapLayersForUser = selector({
  key: 'rsmMapLayersForUser',
  get: ({ get }) => {
    const orgWithPowerLines: boolean = get(rsaOrgWithPowerLines);
    const isUserOrgPge: boolean = get(rsaIsUserOrgPge);
    const dynamicMapLayerConfigs = get(rsmNearByAssetEligibleLocations);

    return [
      ...dynamicMapLayerConfigs,
      ...(orgWithPowerLines ? [MapAssetDataSet.PowerLine] : []),
      ...(isUserOrgPge
        ? [MapAssetDataSet.PgeLocation, MapAssetDataSet.PgeSubstation, MapAssetDataSet.PgeHydroPlant]
        : []),
    ];
  },
});

/**
 * @description - Get customer assets near a specific lon/lat
 * - **asset** - A customer's property (e.g. Planation, Power Facility, Powerline, etc)
 * @NOTE - We have not deprecated this because it manages caching, preventing duplicate requests, and enables use of Suspense
 * - This approach is not good for when we need to re-fetch within a pageview
 */
export const rsmFetchNearByAssetsByIncidentId = selectorFamily({
  key: 'rsmFetchNearByAssetsByIncidentId',
  get:
    (incidentId?: Incident['id']) =>
    async ({ get }) => {
      const featureFlags = get(rsfFeatureFlags);
      const lonLat = get(rsiIncidentLonLatById(incidentId));
      const isNewNearbyAssets =
        getLocalBooleanFlagOverride('FE-Use-Static-Tiles') || featureFlags['FE-Use-Static-Tiles']?.value;

      if (!isNewNearbyAssets) {
        return get(rsmFetchNearByAssetsOLD(lonLat));
      }

      return get(rsmFetchNearByAssetsNEW(incidentId));
    },
});

/**
 * @description - Get customer assets near a specific lon/lat
 * - **asset** - A customer's property (e.g. Planation, Power Facility, Powerline, etc)
 * @NOTE - We have not deprecated this because it manages caching, preventing duplicate requests, and enables use of Suspense
 * - This approach is not good for when we need to re-fetch within a pageview
 */
export const rsmFetchNearByAssetsOLD = selectorFamily({
  key: 'rsmFetchNearByAssetsOLD',
  get:
    (lonLat?: LonLat) =>
    async ({ get }) => {
      const fetchMapLayerConfigStatus = get(rsmFetchMapLayerConfigStatus);
      const mapLayers = get(rsmMapLayersForUser);
      const nearbyAssetsRadiusInMiles = get(rsaNearbyAssetRadiusInMiles);

      if (!hasLngLat(lonLat) || fetchMapLayerConfigStatus !== ApiCallStatus.HasValue || !mapLayers.length) {
        return null;
      }

      return await MapApis.apiGetNearByAssetsOLD(lonLat.lat, lonLat.lon, nearbyAssetsRadiusInMiles, mapLayers);
    },
});

/**
 * @description - Get customer assets near a specific incident
 * - **asset** - A customer's property (e.g. Planation, Power Facility, Powerline, etc)
 * @NOTE - We have not deprecated this because it manages caching, preventing duplicate requests, and enables use of Suspense
 * - This approach is not good for when we need to re-fetch within a pageview
 */
export const rsmFetchNearByAssetsNEW = selectorFamily({
  key: 'rsmFetchNearByAssetsNEW',
  get:
    (incidentId: Incident['id']) =>
    async ({ get }) => {
      const fetchMapLayerConfigStatus = get(rsmFetchMapLayerConfigStatus);
      const mapLayers = get(rsmMapLayersForUser);
      const nearbyAssetRadiusInMeters = get(rsaNearbyAssetRadiusInMeters);
      const lonLat = get(rsiIncidentLonLatById(incidentId));

      if (
        !hasLngLat(lonLat) ||
        !incidentId ||
        fetchMapLayerConfigStatus !== ApiCallStatus.HasValue ||
        !mapLayers.length
      ) {
        return null;
      }

      return await MapApis.apiGetNearByAssets(incidentId, nearbyAssetRadiusInMeters);
    },
});

/**
 * @description - Returns the number of nearby assets associated with the incident id
 */
export const rsmNearByAssetCountByIncidentId = selectorFamily<number, number>({
  key: 'rsmNearByAssetCountByIncidentId',
  get:
    (incidentId) =>
    ({ get }) => {
      const nearbyAssets = get(rsmFetchNearByAssetsByIncidentId(incidentId));

      return nearbyAssets?.length || 0;
    },
});

/**
 * @description - Returns the distance to the closest asset associated with the incident id
 */
export const rsmDistanceToClosestAssetByIncidentId = selectorFamily<string, number>({
  key: 'rsmDistanceToClosestAssetByIncidentId',
  get:
    (incidentId) =>
    ({ get }) => {
      const nearbyAssets = get(rsmFetchNearByAssetsByIncidentId(incidentId));
      const isMetric = get(rsaIsMetric);

      if (!nearbyAssets?.length) {
        return null;
      }
      const assetsSortedByDistance = _sortBy(nearbyAssets || [], 'properties.MinDistanceInMeters');
      const closestAsset = assetsSortedByDistance[0];
      const singularAsset = nearbyAssets.length === 1;

      return `~${
        isMetric
          ? metersToKilometers(closestAsset.properties.MinDistanceInMeters) + ' km'
          : metersToMiles(closestAsset.properties.MinDistanceInMeters) + ' mi'
      } to ${singularAsset ? '' : 'closest '}asset`;
    },
});

export const rsmHighlighPgeFacilityIds = atom<string[]>({
  key: 'rsmHighlighPgeFacilityIds',
  default: [],
});

/**
 * static forico 50k layer visibility
 * set to false if dynamic layer found
 * @todo remove once dynamic forico 50k layer is added [RD-5666]
 */
export const rsmIsStaticForico50kVisible = atom<boolean>({
  key: 'rsmIsStaticForico50kVisible',
  default: true,
});

/**
 * static tfs brigade layer visibility
 * set to false if dynamic layer found
 * @todo remove once dynamic tfs brigade layer is added [RD-5667]
 */
export const rsmIsStaticTFSBrigadeVisible = atom<boolean>({
  key: 'rsmIsStaticTFSBrigadeVisible',
  default: true,
});

/**
 * static green triangle layers visibility
 * @todo remove once dynamic green triangle layers are added [RD-5671]
 */
export const rsmGTStaticMapLayerVisibility = atom<LayerVisibility>({
  key: 'rsmGTStaticMapLayerVisibility',
  default: {
    'gtfa-cfa-map-index': true,
    'gtfa-cfs-map-index': true,
    'gtfa-dispatch-zones': true,
    'gtfa-mapbook': true,
  },
});

/**
 * static pge layers visibility
 * @todo remove once dynamic pge layers are added [RD-5692]
 */
export const rmsPGEStaticMapLayerVisibility = atom<LayerVisibility>({
  key: 'rmsPGEStaticMapLayerVisibility',
  default: {
    'pge-overhead-mainline': true,
    'pge-overhead-tapline': true,
    'pge-underground-tapline': true,
    'pge-underground-mainline': true,
    'pge-facilities': true,
  },
});

/**
 * static HVP plantation layer visibility
 * @todo remove once dynamic HVP plantation layer is added [RD-5678]
 */
export const rsmIsStaticHVPPlantationVisible = atom<boolean>({
  key: 'rsmIsStaticHVPPlantationVisible',
  default: true,
});

/**
 * The metadata for all the map layers viewable by the user
 */
export const rsmMapLayerTilesMetadata = atom<{ [key: MapLayerConfig['id']]: MapTilesMetadata }>({
  key: 'rsmMapLayerTilesMetadata',
  default: {},
});

/**
 * @description The status of fetching map tile metadata
 */
export const rsmFetchTilesMetadataStatus = atom<{ [key: MapLayerConfig['id']]: ApiCallStatus }>({
  key: 'rsmFetchTilesMetadataStatus',
  default: {},
});

/** @description - Gets the metadata for the given map layer Id */
export const rsmMapLayerTilesMetadataById = selectorFamily({
  key: 'rsmMapLayerTilesMetadataById',
  get:
    (mapLayerConfigId: MapLayerConfig['id']) =>
    ({ get }) => {
      const metaData = get(rsmMapLayerTilesMetadata);

      return metaData[mapLayerConfigId];
    },
});

/**
 * @description Returns the minzoom as a number for the given mapLayer Id
 */
export const rsmMapLayerMinzoomById = selectorFamily({
  key: 'rsmMapLayerMinzoomById',
  get:
    (mapLayerConfigId: MapLayerConfig['id']) =>
    ({ get }) => {
      const metaData = get(rsmMapLayerTilesMetadataById(mapLayerConfigId));

      if (!metaData?.minzoom) {
        return null;
      }

      return Number(metaData?.minzoom);
    },
});

/**
 * @description Returns the minzoom as a number for the given mapLayer Id
 */
export const rsmMapLayerMaxzoomById = selectorFamily({
  key: 'rsmMapLayerMaxzoomById',
  get:
    (mapLayerConfigId: MapLayerConfig['id']) =>
    ({ get }) => {
      const metaData = get(rsmMapLayerTilesMetadataById(mapLayerConfigId));
      if (!metaData?.maxzoom) {
        return null;
      }

      return Number(metaData?.maxzoom);
    },
});
