/**
 * This file is meant to be used for when accessing state about all incidents
 */
import { DEFAULT_PLAYER_PERIOD, INCIDENT_FILTER_QUERY_FIELD, INCIDENT_OCV_FILTER_QUERY_FIELD } from 'config/constants';
import { localStorageEffect } from 'data/effect';
import _sortBy from 'lodash/sortBy';
import { getIncidentCameraNames } from 'pages/MapView/SideMenu/IncidentsList/IncidentItem/IncidentItem.helpers';
import { atom, selector, selectorFamily } from 'recoil';
import {
  ApiCallStatus,
  CoordinateSystem,
  GeoLocation,
  Incident,
  INCIDENT_LABEL,
  IncidentCamera,
  IncidentLabel,
  IncidentMarkPosition,
  LonLat,
  Mark,
  PanoData,
  Station,
} from 'types';
import {
  getGDACoordinatesFromLonLat,
  getIncidentCamera,
  getIncidentLabel,
  getRotationForIncident,
  incidentResolutionTitle,
  stringifyGDACoordinates,
  stringifyLonLat,
} from 'utils';

import { rsaHasAttachIncidentPermission, rsaIsSomeOrgPanoType, rsaUserCoordinateSystem } from '../authStore';
import { rsmClickedIncidentInfo } from '../mapStore/mapLayerPopups';
import { rssQueryStationById, rssUserStationsMap } from '../stationStore';

/**
 * @description Whether the user is currently marking an incident
 */
export const rsiIsMarkingFire = atom<boolean>({
  key: 'rsiIsMarkingFire',
  default: false,
});

/**
 * @description A user's current incident mark
 */
export const rsiIncidentMark = atom<Mark>({
  key: 'rsiIncidentMark',
  default: null,
});

/** Incidents hovered */
export const rsiHoverIncidentsId = atom<number[]>({
  key: 'rsiHoverIncidentsId',
  default: [],
});

/** API call status of fetching incidents */
export const rsiFetchIncidentsStatus = atom<ApiCallStatus>({
  key: 'rsiFetchIncidentsStatus',
  default: ApiCallStatus.Pending,
});

/** Keyword to search incident/station list */
export const rsiKeywordQuery = atom<string>({
  key: 'rsiKeywordQuery',
  default: '',
});

/** Keyword to search incident/station list for OCV*/
export const rsiOCVKeywordQuery = atom<string>({
  key: 'rsiOCVKeywordQuery',
  default: '',
});

export const rsiHasOCVSearchValue = selector({
  key: 'rsiHasOCVSearchValue',
  get: ({ get }) => {
    const keyword = get(rsiOCVKeywordQuery);

    return keyword.length > 0;
  },
});

/**
 * The id of the selected camera for the incident
 */
export const rsiSelectedStationId = atom<number>({
  key: 'rsiSelectedStationId',
  default: null,
});

/** Incident type filters to search incident/station list */
export const rsiFilterQuery = atom<IncidentLabel[]>({
  key: 'rsiFilterQuery',
  default: [],
  effects: [localStorageEffect(INCIDENT_FILTER_QUERY_FIELD, [])],
});

export const rsiIsFiltersCustomized = selector({
  key: 'rsiIsFiltersCustomized',
  get: ({ get }) => {
    const filterQueries = get(rsiFilterQuery);

    return filterQueries?.length > 0;
  },
});

/** Incident type filters to search incident/station list for OCV*/
export const rsiOCVSelectedFilters = atom<IncidentLabel[]>({
  key: 'rsiOCVSelectedFilters',
  default: [],
  effects: [localStorageEffect(INCIDENT_OCV_FILTER_QUERY_FIELD, [])],
});

export const rsiIsOCVFiltersCustomized = selector({
  key: 'rsiIsOCVFiltersCustomized',
  get: ({ get }) => {
    const selectedFilters = get(rsiOCVSelectedFilters);

    return selectedFilters.length > 0;
  },
});

export const rsiIsOCVDisplayingIncidentType = selectorFamily<boolean, IncidentLabel>({
  key: 'rsiIncidentById',
  get:
    (incidentType) =>
    ({ get }) => {
      const status = get(rsiOCVSelectedFilters);
      const keyword = get(rsiOCVKeywordQuery);

      const incidentIdsToDisplay = get(
        rsiSortedUserIncidentsIdsByLabel({
          label: incidentType,
          sortBy: ['startTime'],
        }),
      );

      const isDefaultOnEmptyFilter =
        status.length === 0 && [INCIDENT_LABEL.CONFIRMED, INCIDENT_LABEL.CLOSED].includes(incidentType);
      const isMatchingFilters = status.includes(incidentType);
      const isMatchingSearch = keyword.length === 0 || incidentIdsToDisplay.length > 0;

      return (isMatchingFilters || isDefaultOnEmptyFilter) && isMatchingSearch;
    },
});

/** Whether open the share incident modal. */
export const rsiShareModalOpen = atom<boolean>({
  key: 'rsiShareModalOpen',
  default: false,
});

/** The new incident to create */
export const rsiNewIncidentToCreate = atom<Incident>({
  key: 'rsiNewIncidentToCreate',
  default: null,
});

/** Last time we fetch the full incidents list */
export const rsiUserIncidentsTs = atom<number>({
  key: 'rsiUserIncidentsTs',
  default: 0,
});

/** Call API to fetch all incidents (probably with up to 10k incidents) */
export const rsiUserIncidents = atom<Incident[]>({
  key: 'rsiUserIncidents',
  default: [],
});

/** The incident to display on the IncidentDetails page */
export const rsiFeaturedIncident = atom<Incident>({
  key: 'rsiFeaturedIncident',
  default: null,
});

/** The last time the featuredIncident was fetched */
export const rsiFeaturedIncidentTs = atom<number>({
  key: 'rsiFeaturedIncidentTs',
  default: 0,
});

/** The Incident mark position for the popup */
export const rsiIncidentMarkPositionForPopup = atom<IncidentMarkPosition>({
  key: 'rsiIncidentMarkPositionForPopup',
  default: null,
});

/**
 * User incidents sorted for Incidents List
 */
export const rsiSortedUserIncidents = selector<Incident[]>({
  key: 'rsiSortedUserIncidents',
  get: ({ get }) => {
    const userIncidents = get(rsiUserIncidents);

    return _sortBy(userIncidents, ['order', 'startTime']).reverse();
  },
});

/**
 * Gets active incidents to be displayed to the user
 * Incidents of type: Possible/Confirmed
 */
export const rsiActiveUserIncidents = selector<Incident[]>({
  key: 'rsiActiveUserIncidents',
  get: ({ get }) => {
    const userIncidents = get(rsiUserIncidents);

    return userIncidents.filter(
      (incident) =>
        getIncidentLabel(incident) === INCIDENT_LABEL.POSSIBLE ||
        getIncidentLabel(incident) === INCIDENT_LABEL.CONFIRMED,
    );
  },
});

/**
 * @description Returns the User incidents filtered by label
 * and sorted by the provided list of properties.
 */
export const rsiSortedUserIncidentsByLabel = selectorFamily<Incident[], { label: IncidentLabel; sortBy: string[] }>({
  key: 'rsiSortedUserIncidentsByLabel',
  get:
    ({ label, sortBy }) =>
    ({ get }) => {
      const userIncidents = get(rsiUserIncidents);
      const filteredIncidents = userIncidents.filter((incident) => getIncidentLabel(incident) === label);

      return _sortBy(filteredIncidents, sortBy).reverse();
    },
});

/**
 * @description Returns the number of user incidents for a specific label
 */
export const rsiUserIncidentsCountByLabel = selectorFamily<number, { label: IncidentLabel }>({
  key: 'rsiUserIncidentsCountByLabel',
  get:
    ({ label }) =>
    ({ get }) => {
      const userIncidents = get(rsiUserIncidents);
      const filteredIncidents = userIncidents.filter((incident) => getIncidentLabel(incident) === label);

      return filteredIncidents.length;
    },
});

/**
 * @description Returns the live Incidents that have any camera for the provided station ID
 * live excludes ended and dismissed incidents
 */
export const rsiUserIncidentsForStation = selectorFamily<Incident[], number>({
  key: 'rsiUserIncidentsForStation',
  get:
    (stationId) =>
    ({ get }) => {
      const userIncidents = get(rsiUserIncidents);

      return userIncidents.filter((incident) => {
        const isAnyCameraFromStation = incident?.cameras?.some((camera) => camera.id === stationId);

        return !incident.endTime && !incident.closeTime && isAnyCameraFromStation;
      });
    },
});

/**
 * @warning - Please prefer getting the data you need, avoid passing around whole incident
 * @description Returns the Incident with the given id
 */
export const rsiIncidentById = selectorFamily<Incident, number>({
  key: 'rsiIncidentById',
  get:
    (incidentId) =>
    ({ get }) => {
      const userIncidents = get(rsiUserIncidents);

      return userIncidents.find((incident) => incident.id === incidentId);
    },
});

/**
 * @description The currently hovered incident
 */
export const rsiHoveredIncident = selector<Incident>({
  key: 'rsiHoveredIncident',
  get: ({ get }) => {
    const userIncidents = get(rsiUserIncidents);
    const hoveredIncidentIds = get(rsiHoverIncidentsId);

    return userIncidents.find((incident) => hoveredIncidentIds.includes(incident.id)) ?? null;
  },
});

/**
 * @description Returns the start time of the incident
 */
export const rsiIncidentStartTimeById = selectorFamily<number, number>({
  key: 'rsiIncidentStartTimeById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));

      return incident?.startTime;
    },
});

/**
 * @description Returns the end time or close time of an incident
 */
export const rsiIncidentEndTimeById = selectorFamily<number, number>({
  key: 'rsiIncidentEndTimeById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));

      return incident?.endTime || incident?.closeTime;
    },
});

/**
 * @description Returns the Incident Title of the incdent with the given id
 */
export const rsiIncidentTitleById = selectorFamily<string, number>({
  key: 'rsiIncidentTitleById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));
      const label = getIncidentLabel(incident);

      if (!incident) {
        return '';
      }

      return `${incident?.name || incidentResolutionTitle[label]} - #${incident?.id || ''}`;
    },
});

/**
 * @description Returns the Camera offline status of the incdent with the given id
 * @note Incidents with latest panos more than 10 minutes old are considered offline
 */
export const rsiIsIncidentCameraOfflineById = selectorFamily<boolean, number>({
  key: 'rsiIsIncidentCameraOfflineById',
  get:
    (incidentId) =>
    ({ get }) => {
      const latestPanoData = get(rsiIncidentLatestPrimaryPanoDataById(incidentId));

      const incidentType = get(rsiIncidentLabelById(incidentId));

      const incidentTypeHasLivePanos =
        incidentType === INCIDENT_LABEL.POSSIBLE || incidentType === INCIDENT_LABEL.CONFIRMED;

      if (!latestPanoData || !incidentTypeHasLivePanos) {
        return false;
      }

      const latestPanoTime = new Date(latestPanoData[0][1]).valueOf();
      const currentTime = new Date().valueOf() / 1000;
      const elapsedSecondsFromCurrentTime = currentTime - latestPanoTime;

      return elapsedSecondsFromCurrentTime > DEFAULT_PLAYER_PERIOD;
    },
});

/**
 * @description Returns IncidentCamera for a given incidentId and stationId
 */
export const rsiIncidentCameraByIdAndStation = selectorFamily<
  IncidentCamera,
  { incidentId: number; stationId: number }
>({
  key: 'rsiIncidentCameraByIdAndStation',
  get:
    ({ incidentId, stationId }) =>
    ({ get }) => {
      if (!stationId || !incidentId) {
        return null;
      }

      const incident = get(rsiIncidentById(incidentId));
      const userStationsMap = get(rssUserStationsMap);

      return getIncidentCamera(incident, stationId, userStationsMap);
    },
});

/**
 * @description Returns IncidentCamera for a given incidentId and stationId
 */
export const rsiIncidentCameraGeolocationById = selectorFamily<GeoLocation, { incidentId: number; stationId: number }>({
  key: 'rsiIncidentCameraGeolocationById',
  get:
    ({ incidentId, stationId }) =>
    ({ get }) => {
      if (!stationId || !incidentId) {
        return null;
      }

      const incidentCamera = get(rsiIncidentCameraByIdAndStation({ incidentId, stationId }));

      return incidentCamera?.geolocation;
    },
});

/**
 * @description Returns combined object with IncidentCamera and Station info.
 * Used for PanoCanvas input
 */
export const rsiIncidentCameraStationByIncidentAndStation = selectorFamily<
  (IncidentCamera & Station) | Record<string, never>,
  { incidentId: number; stationId: number }
>({
  key: 'rsiIncidentCameraStationByIncidentAndStation',
  get:
    ({ incidentId, stationId }) =>
    ({ get }) => {
      const incidentCamera = get(rsiIncidentCameraByIdAndStation({ incidentId, stationId }));
      const station = get(rssQueryStationById(stationId));

      if (!incidentCamera || !station) {
        return {};
      }

      return { ...station, ...incidentCamera };
    },
});

/**
 * @description Returns the Incident Lonlat
 * If the Incident has not coordinates,
 * the lonLat will return { lon: null, lat: null }
 */
export const rsiIncidentLonLatById = selectorFamily<LonLat, number>({
  key: 'rsiIncidentPrimaryCoordinatesById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));

      const lonLat = {
        lat: incident?.lat || null,
        lon: incident?.lon || null,
      };

      return lonLat;
    },
});

/**
 * @description Returns the Incident Coordinates
 * - If the incident is gcs: GDA2020 return GDA coords
 * - If the incident is gcs: WGS84 return WGS coords
 */
export const rsiIncidentPrimaryCoordinatesById = selectorFamily<string, number>({
  key: 'rsiIncidentPrimaryCoordinatesById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));
      const coordinateSystem = get(rsaUserCoordinateSystem);

      const lonLat = { lat: incident?.lat, lon: incident?.lon };

      if (!incident || !incident?.lat || !incident?.lon) {
        return null;
      } else if (coordinateSystem === CoordinateSystem.GDA2020) {
        return stringifyGDACoordinates(getGDACoordinatesFromLonLat(lonLat));
      }

      return stringifyLonLat(lonLat);
    },
});

/**
 * @description Returns the Incident's latest pano data from its primary station
 */
export const rsiIncidentLatestPrimaryPanoDataById = selectorFamily<PanoData[], number>({
  key: 'rsiIncidentLatestPrimaryPanoDataById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));
      const primaryStationId = incident?.primary;
      const primaryCamera = incident?.cameras.find((camera) => camera.id === primaryStationId);
      if (primaryCamera && primaryCamera.latestPano) {
        return [[primaryCamera.latestPano.seq, primaryCamera.latestPano.captureTime]] as PanoData[];
      }

      return null;
    },
});

/**
 * @description Returns the Incident Display sourceType and background color
 */
export const rsiIncidentDisplayById = selectorFamily<[string, string], number>({
  key: 'rsiIncidentDisplayById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));

      let [sourceType, backgroundColor] = [incident?.source, 'primary.main'];
      const label = getIncidentLabel(incident);

      switch (label) {
        case INCIDENT_LABEL.CLOSED:
          sourceType = 'closed';
          backgroundColor = 'greys.light';
          break;
        case INCIDENT_LABEL.DISMISSED:
          backgroundColor = 'greys.light';
          break;
        case INCIDENT_LABEL.CONFIRMED:
          sourceType = 'burn';
          backgroundColor = 'secondary.main';
          break;
        case INCIDENT_LABEL.POSSIBLE:
          break;
        default:
          break;
      }

      return [sourceType, backgroundColor];
    },
});

/**
 * @description Returns whether this incident has multiple cameras associated with it
 */
export const rsiIncidentHasMultipleCamerasById = selectorFamily<boolean, number>({
  key: 'rsiIncidentById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident: Incident = get(rsiIncidentById(incidentId));

      return incident?.cameras?.length > 1;
    },
});

/**
 * @description Returns the names of the cameras associated with the incident as an array
 */
export const rsiIncidentCameraNamesById = selectorFamily<string[], number>({
  key: 'rsiIncidentCameraNamesById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incidentItem = get(rsiIncidentById(incidentId));
      const userStationsMap = get(rssUserStationsMap);

      return getIncidentCameraNames(incidentItem, userStationsMap);
    },
});

/** Whether the given Station is involved in the feature incident */
export const rsiIsStationInFeaturedIncident = selectorFamily<boolean, number>({
  key: 'rsiIsStationInFeaturedIncident',
  get:
    (stationId) =>
    ({ get }) => {
      const incident = get(rsiFeaturedIncident);

      return !!incident?.cameras?.some((camera) => {
        return camera?.id === stationId;
      });
    },
});

/** The id of the featured incident */
export const rsiFeaturedIncidentId = selector<number>({
  key: 'rsiFeaturedIncidentId',
  get: ({ get }) => {
    const incident = get(rsiFeaturedIncident);

    return incident?.id;
  },
});

/**
 * Returns sorted Incident Ids
 * @param - [incidentType, sortBy]
 */
export const rsiSortedUserIncidentsIdsByLabel = selectorFamily<number[], { label: IncidentLabel; sortBy: string[] }>({
  key: 'rsiIncidentIdsToDisplayInGrid',
  get:
    ({ label, sortBy }) =>
    ({ get }) => {
      const incidents = get(rsiSortedUserIncidentsByLabel({ label, sortBy }));

      return incidents.map((incident) => incident.id);
    },
});

/**
 * Returns the rotationFrom and rotationTo for the entire incident used for querying panoData for creating the player
 * @returns [rotationFrom, rotationTo]
 */
export const rsiIncidentRotationById = selectorFamily<[number, number], number>({
  key: 'rsiIncidentRotationById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));

      if (!incident) {
        return [0, 0];
      }

      return getRotationForIncident(incident, 'incident');
    },
});

/**
 * Get's the label of an incident
 * - Not to be confused with rsiFetchIncidentLabelById, which fetches the label
 */
export const rsiIncidentLabelById = selectorFamily<IncidentLabel, number>({
  key: 'rsiIncidentLabelById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));

      return getIncidentLabel(incident);
    },
});

/**
 * Returns true if the incident is active, false otherwise
 * Active if of type: Possible/Confirmed
 */
export const rsiIsIncidentActiveById = selectorFamily<boolean, number>({
  key: 'rsiIsIncidentActiveById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));

      if (!incident) {
        return false;
      }

      return (
        getIncidentLabel(incident) === INCIDENT_LABEL.POSSIBLE ||
        getIncidentLabel(incident) === INCIDENT_LABEL.CONFIRMED
      );
    },
});

/**
 * Returns true if the incident with the given Id triangulated
 */
export const rsiIsIncidentTriangulatedById = selectorFamily<boolean, Incident['id']>({
  key: 'rsiIsIncidentTriangulatedById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));

      return !!incident?.lat && !!incident?.lon;
    },
});

/**
 * Returns true if the incident that was clicked on in the map is triangulated
 */
export const rsiIsSelectedIncidentTriangulated = selector({
  key: 'rsiIsSelectedIncidentTriangulated',
  get: ({ get }) => {
    const { incidentId } = get(rsmClickedIncidentInfo);
    const incident = get(rsiIncidentById(incidentId));

    return !!incident?.lat && !!incident?.lon;
  },
});

/**
 * Returns true if the incident that is currently being viewed is a non-triangulated, smoke investigation
 */
export const rsiIsIncidentNonTriangulatedSmokeInvestigation = selector({
  key: 'rsiIsIncidentNonTriangulatedSmokeInvestigation',
  get: ({ get }) => {
    const currentIncident = get(rsiFeaturedIncident);

    return (
      currentIncident?.cameras?.length === 1 &&
      !currentIncident?.lat &&
      !currentIncident?.lon &&
      !currentIncident?.label &&
      !currentIncident?.endTime
    );
  },
});

/**
 * Returns true if the current incident can be attached to the selected incident
 * User must have attach incident permissions, the selected incident must be triangulated, and the current incident must be a smoke investigation
 */
export const rsiCanAttachIncident = selector({
  key: 'rsiCanAttachIncident',
  get: ({ get }) => {
    const hasAttachIncidentPermission = get(rsaHasAttachIncidentPermission);
    const isSelectedIncidentTriangulated = get(rsiIsSelectedIncidentTriangulated);
    const isIncidentNonTriangulatedSmokeInvestigation = get(rsiIsIncidentNonTriangulatedSmokeInvestigation);
    const isCurrentStationInSelectedIncident = get(rsiIsCurrentStationInSelectedIncident);
    const hasBaseIncidentId = get(rsiHasBaseIncidentId);

    return (
      hasAttachIncidentPermission &&
      isSelectedIncidentTriangulated &&
      isIncidentNonTriangulatedSmokeInvestigation &&
      !isCurrentStationInSelectedIncident &&
      !hasBaseIncidentId
    );
  },
});

/**
 * API call status of attaching/detaching incidents
 */
export const rsiAttachIncidentsStatus = atom<ApiCallStatus>({
  key: 'rsiAttachIncidentsStatus',
  default: ApiCallStatus.Pending,
});

/**
 * Returns boolean indicating if a user should view only a featured incident
 */
export const rsiShouldReturnOnlyFeaturedIncidetnt = selector({
  key: 'rsiShouldReturnOnlyFeaturedIncidetnt',
  get: ({ get }) => {
    const marking = get(rsiIsMarkingFire);
    const featuredIncident = get(rsiFeaturedIncident);
    const isFeaturedIncidentActive = get(rsiIsIncidentActiveById(featuredIncident?.id));
    const orgIsPanoType = get(rsaIsSomeOrgPanoType);

    if (featuredIncident && (!isFeaturedIncidentActive || !orgIsPanoType || marking)) {
      return true;
    }

    return false;
  },
});

/**
 * Returns the set of incidents that are currently displaying on the map
 */
export const rsiIncidentsToDisplay = selector({
  key: 'rsiIncidentsToDisplay',
  get: ({ get }) => {
    const activeIncidents = get(rsiActiveUserIncidents);
    const featuredIncident = get(rsiFeaturedIncident);
    const hoveredIncident = get(rsiHoveredIncident);
    const shouldReturnOnlyFeaturedIncidetnt = get(rsiShouldReturnOnlyFeaturedIncidetnt);

    if (shouldReturnOnlyFeaturedIncidetnt) {
      return [featuredIncident];
    }

    const incidentsToDisplay = [...activeIncidents];
    if (hoveredIncident) {
      incidentsToDisplay.push(hoveredIncident);
    }

    return incidentsToDisplay;
  },
});

/**
 * Returns true if the camera in the currently viewed incident
 * is already in the selected incident that is clicked on in the map
 */
export const rsiIsCurrentStationInSelectedIncident = selector({
  key: 'rsiIsCurrentStationInSelectedIncident',
  get: ({ get }) => {
    const { incidentId } = get(rsmClickedIncidentInfo);
    const incident = get(rsiIncidentById(incidentId));
    const featuredIncident = get(rsiFeaturedIncident);

    return featuredIncident?.cameras?.some((featuredIncidentCamera) =>
      incident?.cameras?.some((incidentCamera) => incidentCamera.id === featuredIncidentCamera?.id),
    );
  },
});

/**
 * Returns true if the currently viewed incident has a base incident id
 */
export const rsiHasBaseIncidentId = selector({
  key: 'rsiHasBaseIncidentId',
  get: ({ get }) => {
    const incident = get(rsiFeaturedIncident);

    return !!incident?.baseIncidentId;
  },
});
