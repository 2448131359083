import React from 'react';
import UserMenus from 'components/UserMenus';
import { rsaUserOrgType } from 'data';
import { Location } from 'history';
import AdminHeader from 'pages/Admin/AdminHeader';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import PanoramaHorizontal from '@mui/icons-material/PanoramaHorizontal';
import Room from '@mui/icons-material/Room';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { bgColors, orgText } from './Header.helpers';
import styles from './Header.styles';
import HeaderTitle from './HeaderTitle';

interface Props {
  variant?: 'admin' | 'default';
}

export default function Header({ variant }: Props) {
  const location: Location = useLocation();
  const orgType = useRecoilValue(rsaUserOrgType);
  const isCustomOrgHeader = !!orgText[orgType];

  const isPanoPage = location.pathname.includes('/map/stations/panos');

  const bgColorStyle = styles[bgColors[orgType]];

  if (variant === 'admin') {
    return <AdminHeader />;
  }

  return (
    <Box
      component='header'
      sx={[
        ...(Array.isArray(styles.header) ? styles.header : [styles.header]),
        ...(isPanoPage && Array.isArray(styles.fixed) ? styles.fixed : [styles.fixed]),
        ...(isCustomOrgHeader && Array.isArray(styles.showBorder) ? styles.showBorder : [styles.showBorder]),
      ]}
      data-cy='header'
    >
      <Grid
        container
        sx={[
          ...(Array.isArray(styles.content) ? styles.content : [styles.content]),
          ...(Array.isArray(bgColorStyle) ? bgColorStyle : [bgColorStyle]),
        ]}
        data-cy='header-content'
      >
        <HeaderTitle />
        <Grid item xs={4} sx={styles['trailing-icons-wrapper']}>
          {isPanoPage && (
            <Button
              variant='contained'
              component={RouterLink}
              to='/map'
              data-cy='view-main-link'
              size='small'
              sx={styles['header-button']}
              startIcon={<Room />}
            >
              View Main
            </Button>
          )}

          {['/', '/map', '/map/stations', '/map/incidents'].includes(location.pathname) && (
            <Button
              variant='contained'
              component={RouterLink}
              to='/map/stations/panos'
              data-cy='view-panos-link'
              size='small'
              sx={styles['header-button']}
              startIcon={<PanoramaHorizontal />}
            >
              View Panos
            </Button>
          )}
          <UserMenus />
        </Grid>
      </Grid>
    </Box>
  );
}
