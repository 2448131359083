import React from 'react';
import iconPanoLogoWhite from 'assets/icons/pano-logo-white.svg';
import UserMenus from 'components/UserMenus';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import styles from './AdminHeader.styles';

export const AdminHeader = () => {
  const theme = useTheme();

  return (
    <Box component='header' sx={styles.adminHeader} data-cy='admin-header'>
      <Box sx={styles.title}>
        <Box component='img' height='28px' alt='Pano' src={iconPanoLogoWhite} sx={styles.logo} data-cy='logo-img' />
        <Typography component='span' variant='h6' fontWeight={theme.typography.fontWeightBold}>
          Admin Center
        </Typography>
      </Box>

      <UserMenus />
    </Box>
  );
};

export default AdminHeader;
