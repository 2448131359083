import {
  INCIDENT_BAR_BORDER_WIDTH_SCALE,
  INCIDENT_BAR_UNCALIBRATED_LENGTH,
  MAX_UNCALIBRATED_CHEVRONS_COUNT,
} from 'config/constants';
import dayjs from 'dayjs';
import _compact from 'lodash/compact';
import _every from 'lodash/every';
import _flatten from 'lodash/flatten';
import _forEach from 'lodash/forEach';
import _groupBy from 'lodash/groupBy';
import _head from 'lodash/head';
import _mapValues from 'lodash/mapValues';
import _round from 'lodash/round';
import _trim from 'lodash/trim';
import { LayerProps } from 'react-map-gl';
import {
  ChevronFeatureProps,
  ClickableFeatureProps,
  CoordinatesForDisplay,
  CoordinateSystem,
  FeatureFadeProperties,
  GDACoordinates,
  GradientRectangleFeatureProps,
  Incident,
  INCIDENT_LABEL,
  IncidentColors,
  IncidentLabel,
  LonLat,
  LonLatArray,
  MapMode,
  MapSourceData,
  OutlineFeatureProps,
  RectangleFeatureProps,
  SlantedLinesFeatureProps,
  SlantFeaturesBetweenDistancesProps,
  StackedChevronFeatureProps,
  Station,
  StationsMap,
  TriangleFeatureProps,
} from 'types';
import {
  CAMERA_IN_VIEW_CONE_ANGLE,
  CAMERA_UNCERTAINTY_ANGLE,
  computeDestinationPointLonLat,
  getCameraUncertaintyAngleByMapZoom,
  getChevronOutlinePoints,
  getChevronPoints,
  getGDACoordinatesFromLonLat,
  getIncidentBarCenterToEdgeWidth,
  getIncidentLabel,
  getPolygonPointsLatLng,
  getRectanglePoints,
  getRectPointsLatLng,
  getSlantedLinePoints,
  getTrianglePoints,
  getTwoDigitHexString,
  hasLngLat,
  hasNoLngLat,
} from 'utils';

const MAX_LNGLAT_SCALE = 7;

const lightMode = 'mapbox://styles/mapbox/light-v9';
const darkMode = 'mapbox://styles/mapbox/dark-v9';
const outdoorsMode = 'mapbox://styles/mapbox/outdoors-v11';
const satelliteStreetsMode = 'mapbox://styles/mapbox/satellite-streets-v11';

export const mapModes: Record<MapMode, string> = {
  light: lightMode,
  dark: darkMode,
  map: outdoorsMode,
  satellite: satelliteStreetsMode,
};

export const mapList: { name: MapMode; img: string }[] = [
  { name: 'map', img: 'outdoors-v11' },
  { name: 'satellite', img: 'satellite-streets-v11' },
];

export const incidentResolutionTitle: Record<IncidentLabel, string> = {
  possible: 'Smoke Investigation',
  confirmed: 'Alerted Incident',
  dismissed: 'Dismissed Investigation',
  closed: 'Closed Incident',
};

export const getIncidentLabelTime = (label: IncidentLabel, readableTime = ''): string =>
  ({
    [INCIDENT_LABEL.POSSIBLE]: `Detected ${readableTime}`,
    [INCIDENT_LABEL.CONFIRMED]: `Active for ${readableTime}`,
    [INCIDENT_LABEL.DISMISSED]: `Dismissed ${readableTime}`,
    [INCIDENT_LABEL.CLOSED]: `Closed for ${readableTime}`,
  }[label] || readableTime);

export const incidentSourceDescPopup: Record<string, string> = {
  dispatch: '911',
  cv: 'PanoCam',
  burn: 'Active',
  satellite: 'Satellite',
  closed: 'Closed',
  user: 'User',
};

//function to calculate the time period incident start time and now
export function getIncidentHappenedDuration(startTime: number): string {
  let durationSymbol = 'm';
  const endTime: dayjs.Dayjs = dayjs();
  const seconds: number = endTime.diff(startTime * 1000, 'seconds');
  let duration: number = seconds / 60;
  if (duration < 1) {
    duration = 1;
  }
  if (duration > 60) {
    duration = duration / 60;
    durationSymbol = 'h';
    if (duration > 24) {
      duration = duration / 24;
      durationSymbol = 'd';
    }
  }

  return Math.floor(duration) + durationSymbol;
}

//fill color for camera area circle
export const stationDataLayer: LayerProps = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': {
      property: 'opacity',
      stops: [
        [0, 'rgba(53, 131, 188, 0.15)'],
        [1, 'rgba(53, 131, 188, 0.15)'],
      ],
    },
    'fill-opacity': 1,
  },
};

/**
 * Returns a map layer styling object that uses a feature's color
 * and opacity properties for styling
 */
export const getPolygonLayerDynamicStyling = (id: string) => ({
  id: `${id}`,
  type: 'fill',
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': ['get', 'opacity'],
    'fill-outline-color': 'transparent',
  },
});

/**
 * Get camera circle polygon coordinates
 * These are used to create the map layer
 */
export function getStationViewshedFeatureData(perimeterPoints: number[][]): GeoJSON.FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: { opacity: 0 },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[perimeterPoints]],
        },
      },
    ],
  };
}

const INCIDENT_COLORS: IncidentColors = {
  POSSIBLE: '6, 118, 188',
  CONFIRMED: '226, 3, 9',
  CLOSED: '108, 115, 132',
  DISMISSED: '108, 115, 132',
};

/**
 * Styling for the incidents map layers
 */
export const getIncidentDataLayer = (
  /** the incident label, to determine its color */
  label: IncidentLabel,
): LayerProps => {
  const rgb = INCIDENT_COLORS[label.toUpperCase() as keyof typeof INCIDENT_COLORS] || '226, 3, 9';
  const id = label ? `${label.toLowerCase()}-data` : 'data';

  return {
    id,
    type: 'fill',
    paint: {
      'fill-color': {
        property: 'opacity',
        stops: [
          [0.1, `rgba(${rgb}, 0.1)`],
          [0.5, `rgba(${rgb}, 0.5)`],
          [1, `rgba(${rgb}, 1)`],
        ],
      },
      'fill-outline-color': 'transparent',
      'fill-opacity': 1,
    },
  };
};

/**
 * The styling associated with incident and camera bearing cones
 */
export const getIncidentConeStyling = (): LayerProps => {
  const id = 'data';

  return {
    id,
    type: 'fill',
    paint: {
      'fill-color': 'rgba(27, 32, 38, 0.2)',
      'fill-outline-color': 'transparent',
      'fill-opacity': 1,
    },
  };
};

export const getAllIncidentsDataLayer = (): Record<string, LayerProps> => {
  const labeledLayers: Record<string, LayerProps> = {};
  Object.keys(INCIDENT_COLORS).forEach((definedLabel) => {
    labeledLayers[definedLabel.toLowerCase()] = getIncidentDataLayer(definedLabel as IncidentLabel);
  });

  return labeledLayers;
};

export const labeledIncidentLayers: Record<string, LayerProps> = getAllIncidentsDataLayer();

export const basePolygon = (points: LonLatArray[], opacity = 1): GeoJSON.Feature<GeoJSON.MultiPolygon> => ({
  type: 'Feature',
  properties: { opacity },
  geometry: {
    type: 'MultiPolygon',
    coordinates: [[points]],
  },
});

/**
 * A GeoJSON polygon feature from a set of points
 * @param points polygon verticies
 * @param color polygon fill color
 * @param opacity fill color opacity
 */
export const coloredPolygonFeature = (
  points: LonLatArray[],
  color: string,
  opacity = 1,
  properties?: GeoJSON.GeoJsonProperties,
): GeoJSON.Feature => ({
  type: 'Feature',
  properties: { color, opacity, ...properties },
  geometry: {
    type: 'MultiPolygon',
    coordinates: [[points]],
  },
});

export function getStationViewPolygon(
  station: Station,
  bearing: number = null,
  angle: number = CAMERA_IN_VIEW_CONE_ANGLE,
): MapSourceData {
  const polygonPoints = getPolygonPointsLatLng({
    incidentCamera: null,
    station,
    coneAngle: angle,
    canvasBearing: bearing,
  });
  const cone = basePolygon(polygonPoints, 0.3);

  return {
    type: 'FeatureCollection',
    features: [cone],
  };
}

/**
 * Returns a GeoJSON rectanlge polygon feature
 */
export const getRectangleFeature = ({
  calculationStartPoint,
  start,
  end,
  color,
  zoom,
  opacity,
  bearing,
  widthScale = 1,
}: RectangleFeatureProps) => {
  const centerToEdgeWidth = getIncidentBarCenterToEdgeWidth(zoom) / widthScale;
  const rectanglePoints = getRectanglePoints({ calculationStartPoint, start, end, bearing, centerToEdgeWidth });

  return coloredPolygonFeature(rectanglePoints, color, opacity);
};

export const getClickableFeature = ({
  calculationStartPoint,
  start,
  end,
  zoom,
  bearing,
  incidentCameraId,
  incidentId,
}: ClickableFeatureProps) => {
  const centerToEdgeWidth = getIncidentBarCenterToEdgeWidth(zoom);
  const rectanglePoints = getRectanglePoints({ calculationStartPoint, start, end, bearing, centerToEdgeWidth });

  return coloredPolygonFeature(rectanglePoints, 'transparent', 0, { incidentCameraId, incidentId });
};
/**
 * The GEOJson features that make up the dotted line between the end of an incident's
 * viewshed and the beginning of the incidents predicted range
 */
export const getDottedLineFeatures = ({
  calculationStartPoint,
  start,
  end,
  color,
  opacity,
  zoom,
  bearing,
}: RectangleFeatureProps) => {
  const features = [];
  const centerToEdgeWidth = getIncidentBarCenterToEdgeWidth(zoom) / INCIDENT_BAR_BORDER_WIDTH_SCALE;
  const incidentBarDotLength = getIncidentBarCenterToEdgeWidth(zoom);
  for (let dotStart = start; dotStart < end; dotStart += 2 * incidentBarDotLength) {
    const dotEnd = Math.min(dotStart + incidentBarDotLength, end);
    const rectanglePoints = getRectanglePoints({
      calculationStartPoint,
      start: dotStart,
      end: dotEnd,
      bearing,
      centerToEdgeWidth,
    });
    features.push(coloredPolygonFeature(rectanglePoints, color, opacity));
  }

  return features;
};

/** Returns the number of gradient intervals based on zoom level
 * @note At lower zooms (more zoomed out) lower step counts prevents feature dropping
 */
const getStepCountFromZoomLevel = (zoom: number) => {
  if (zoom > 12) {
    return zoom * 2;
  } else if (zoom > 11) {
    return 5;
  }

  return 2;
};

/** Returns the features for a chevron */
export const getChevronFeatures = ({
  calculationStartPoint,
  start,
  color,
  opacity,
  zoom,
  bearing,
  strokeColor,
  strokeOpacity,
}: ChevronFeatureProps) => {
  const centerToEdgeWidth = getIncidentBarCenterToEdgeWidth(zoom);
  const chevronPoints = getChevronPoints({ calculationStartPoint, start, bearing, centerToEdgeWidth });
  const baseChevron = coloredPolygonFeature(chevronPoints, color, opacity);

  if (strokeColor && strokeOpacity) {
    const chevronOutlinePoints = getChevronOutlinePoints(chevronPoints, bearing, centerToEdgeWidth);
    const outlineChevron = coloredPolygonFeature(chevronOutlinePoints, strokeColor, strokeOpacity);

    return [outlineChevron, baseChevron];
  }

  return [baseChevron];
};

/**
 * Returns the rectangle GeoJSON features that make up a gradient between
 * start and end distances
 */
export const getGradientRectangleFeatures = ({
  calculationStartPoint,
  start,
  end,
  startColor,
  endColor,
  startOpacity,
  endOpacity,
  zoom,
  bearing,
  widthScale = 1,
}: GradientRectangleFeatureProps) => {
  const features = [];

  const gradientSteps = getStepCountFromZoomLevel(zoom);
  const centerToEdgeWidth = getIncidentBarCenterToEdgeWidth(zoom) / widthScale;

  for (let i = 0; i < gradientSteps; i++) {
    const stepColor = getMixedColor(startColor, endColor, i, gradientSteps);
    const stepOpacity = getMixedOpacity(startOpacity, endOpacity, i, gradientSteps);
    const stepStart = start + i * ((end - start) / gradientSteps);
    const stepEnd = start + (i + 1) * ((end - start) / gradientSteps);

    const rectanglePoints = getRectanglePoints({
      calculationStartPoint,
      start: stepStart,
      end: stepEnd,
      centerToEdgeWidth,
      bearing,
    });
    const feature = coloredPolygonFeature(rectanglePoints, stepColor, stepOpacity);
    features.push(feature);
  }

  return features;
};

/**
 * Returns the GeoJSON features of a triangle and two chevrons
 * that represent an incident with an unknown end of range
 */
export const getPointedTipFeatures = ({
  calculationStartPoint,
  start,
  polygonColor,
  polygonOpacity,
  zoom,
  chevronColor,
  chevronOpacity,
  bearing,
  strokeColor,
  strokeOpacity,
}: TriangleFeatureProps) => {
  const centerToEdgeWidth = getIncidentBarCenterToEdgeWidth(zoom);

  const trianglePoints = getTrianglePoints({ calculationStartPoint, start, centerToEdgeWidth, bearing });
  const triangleFeature = coloredPolygonFeature(trianglePoints, polygonColor, polygonOpacity);

  const chevron1Features = getChevronFeatures({
    calculationStartPoint,
    start: start + centerToEdgeWidth,
    color: chevronColor,
    opacity: chevronOpacity,
    zoom,
    bearing,
    strokeColor,
    strokeOpacity,
  });

  const chevron2Features = getChevronFeatures({
    calculationStartPoint,
    start: start + 2 * centerToEdgeWidth,
    color: chevronColor,
    opacity: chevronOpacity,
    zoom,
    bearing,
    strokeColor,
    strokeOpacity,
  });

  return [triangleFeature, ...chevron1Features, ...chevron2Features];
};

/**
 * The GeoJSON features that make up the outline of an incident
 * @note this includes the outline around the solid color of the incident range,
 * the fade in and fade out of the incident range, and the chevron around the
 * pointed tip that indicates the incident has an unknown range
 */
export const getOutlineFeatures = ({
  bearing,
  zoom,
  calculationStartPoint,
  fadeInProperties,
  fadeOutProperties,
  start,
  end,
  color,
  opacity,
}: OutlineFeatureProps) => {
  const features = [];
  const centerToEdgeWidth = getIncidentBarCenterToEdgeWidth(zoom);

  const clockwiseBearing = bearing + 90;
  const counterClockwiseBearing = bearing - 90;
  const distanceFromCenterToOutline = centerToEdgeWidth - centerToEdgeWidth / INCIDENT_BAR_BORDER_WIDTH_SCALE;

  const clockwiseOutlineEdgePoint = computeDestinationPointLonLat(
    calculationStartPoint,
    distanceFromCenterToOutline,
    clockwiseBearing,
  );
  const counterClockwiseOutlineEdgePoint = computeDestinationPointLonLat(
    calculationStartPoint,
    distanceFromCenterToOutline,
    counterClockwiseBearing,
  );

  if (fadeInProperties) {
    features.push(
      ...getGradientRectangleFeatures({
        calculationStartPoint: clockwiseOutlineEdgePoint,
        start: fadeInProperties.location,
        end: start,
        startOpacity: fadeInProperties.opacity,
        startColor: fadeInProperties.color,
        endColor: color,
        endOpacity: opacity,
        zoom,
        bearing,
        widthScale: INCIDENT_BAR_BORDER_WIDTH_SCALE,
      }),
    );
    features.push(
      ...getGradientRectangleFeatures({
        calculationStartPoint: counterClockwiseOutlineEdgePoint,
        start: fadeInProperties.location,
        end: start,
        startOpacity: fadeInProperties.opacity,
        startColor: fadeInProperties.color,
        endColor: color,
        endOpacity: opacity,
        zoom,
        bearing,
        widthScale: INCIDENT_BAR_BORDER_WIDTH_SCALE,
      }),
    );
  }
  features.push(
    getRectangleFeature({
      calculationStartPoint: clockwiseOutlineEdgePoint,
      start,
      end,
      color,
      opacity,
      zoom,
      bearing,
      widthScale: INCIDENT_BAR_BORDER_WIDTH_SCALE,
    }),
  );
  features.push(
    getRectangleFeature({
      calculationStartPoint: counterClockwiseOutlineEdgePoint,
      start,
      end,
      color,
      opacity,
      zoom,
      bearing,
      widthScale: INCIDENT_BAR_BORDER_WIDTH_SCALE,
    }),
  );
  if (fadeOutProperties) {
    features.push(
      ...getGradientRectangleFeatures({
        calculationStartPoint: clockwiseOutlineEdgePoint,
        start: end,
        end: fadeOutProperties.location,
        startOpacity: opacity,
        startColor: color,
        endColor: fadeOutProperties.color,
        endOpacity: fadeOutProperties.opacity,
        zoom,
        bearing,
        widthScale: INCIDENT_BAR_BORDER_WIDTH_SCALE,
      }),
    );
    features.push(
      ...getGradientRectangleFeatures({
        calculationStartPoint: counterClockwiseOutlineEdgePoint,
        start: end,
        end: fadeOutProperties.location,
        startOpacity: opacity,
        startColor: color,
        endColor: fadeOutProperties.color,
        endOpacity: fadeOutProperties.opacity,
        zoom,
        bearing,
        widthScale: INCIDENT_BAR_BORDER_WIDTH_SCALE,
      }),
    );
  } else {
    features.push(
      ...getChevronFeatures({
        calculationStartPoint,
        start: end,
        color,
        opacity,
        zoom,
        bearing,
      }),
    );
  }

  return features;
};

/**
 * Gets a set of slanted features between a start and end location.
 * @note These features are discovered directionally.
 * If moving forward, the slants move up and to the right, away from the starting point.
 * If moving backwards, the slants move down and to the left, towards the starting point.
 */
const getFeaturesForSlantsBetweenDistances = ({
  isMovingForward,
  calculationStartPoint,
  featuresStart,
  solidColorEnd,
  furthestRectangleEnd,
  hasPointedTip,
  bearing,
  centerToEdgeWidth,
  slantAngle,
  featureGap,
  color,
  opacity,
  fadeResultProperties,
  zoom,
}: SlantFeaturesBetweenDistancesProps) => {
  const features = [];
  const gap = isMovingForward ? featureGap : -featureGap;

  const closestRectangleEnd = fadeResultProperties ? fadeResultProperties.location : featuresStart;
  const directionalEnd = isMovingForward ? furthestRectangleEnd : closestRectangleEnd;

  for (
    let slantStart = featuresStart;
    isMovingForward ? slantStart < directionalEnd : slantStart > directionalEnd;
    slantStart += gap
  ) {
    const points = getSlantedLinePoints({
      calculationStartPoint,
      furthestRectangleEnd,
      slantStart,
      directionalEnd,
      hasPointedTip,
      bearing,
      width: centerToEdgeWidth * 2,
      isClockwiseEdge: !isMovingForward,
      slantAngle,
    });

    const styleProperties = { location: solidColorEnd, color, opacity };

    const slantedLineFeature = getStyledSlantedLineFeature({
      points,
      styleProperties,
      fadeResultProperties,
      featureLocation: slantStart,
      zoom,
    });

    features.push(slantedLineFeature);
  }

  return features;
};

/**
 * Takes a set of points and returns a styled feature
 * @note If a slant start is within a fade, this
 * returns the appropriate faded color at that location
 */
export const getStyledSlantedLineFeature = ({
  points,
  styleProperties,
  fadeResultProperties,
  featureLocation,
  zoom,
}: {
  points: LonLatArray[];
  styleProperties: FeatureFadeProperties;
  fadeResultProperties: FeatureFadeProperties;
  featureLocation: number;
  zoom: number;
}) => {
  if (!fadeResultProperties) {
    return coloredPolygonFeature(points, styleProperties.color, styleProperties.opacity);
  }

  const largerProperties =
    styleProperties.location > fadeResultProperties.location ? styleProperties : fadeResultProperties;
  const smallerProperties =
    styleProperties.location < fadeResultProperties.location ? styleProperties : fadeResultProperties;

  if (featureLocation >= largerProperties.location) {
    return coloredPolygonFeature(points, largerProperties.color, largerProperties.opacity);
  } else if (featureLocation <= smallerProperties.location) {
    return coloredPolygonFeature(points, smallerProperties.color, smallerProperties.opacity);
  }
  const fadeColorOpacity = getColorAndOpacityFromLocationWithinFade(
    smallerProperties,
    largerProperties,
    featureLocation,
    zoom,
  );

  return coloredPolygonFeature(points, fadeColorOpacity.color, fadeColorOpacity.opacity);
};

/**
 * Returns the GeoJSON features that make up the slanted lines that are
 * used as decoration within the estimated incident range
 * @note the calculationStartPoint is the incidentCamera lat lon
 * @note Slants are discovered directionally.
 * Some of the slants are calculated moving 'forward' or away from the calculationStartPoint
 * where points 1&2 are on the counter clockwise side of the incident's bar, and a different portion are
 * calculated moving 'backwards' towards the calculationStartPoint where points 1&2 are on the clockwise side.
 */
export const getSlantedLinesFeatures = ({
  calculationStartPoint,
  bearing,
  zoom,
  fadeInProperties,
  fadeOutProperties,
  start,
  end,
  color,
  opacity,
}: SlantedLinesFeatureProps) => {
  const features = [];
  const centerToEdgeWidth = getIncidentBarCenterToEdgeWidth(zoom);
  const slantAngle = 45;

  const counterClockwiseBearing = bearing - 90;
  const counterClockwiseOutlineEdgePoint = computeDestinationPointLonLat(
    calculationStartPoint,
    centerToEdgeWidth,
    counterClockwiseBearing,
  );

  const forwardFeatures = getFeaturesForSlantsBetweenDistances({
    isMovingForward: true,
    calculationStartPoint: counterClockwiseOutlineEdgePoint,
    featuresStart: start,
    solidColorEnd: end,
    furthestRectangleEnd: fadeOutProperties ? fadeOutProperties.location : end,
    hasPointedTip: !fadeOutProperties,
    bearing,
    centerToEdgeWidth,
    slantAngle,
    featureGap: centerToEdgeWidth,
    color,
    opacity,
    fadeResultProperties: fadeOutProperties,
    zoom,
  });

  features.push(...forwardFeatures);

  const clockwiseBearing = bearing + 90;
  const clockwiseOutlineEdgePoint = computeDestinationPointLonLat(
    calculationStartPoint,
    centerToEdgeWidth,
    clockwiseBearing,
  );
  const clockwiseStartDistance = centerToEdgeWidth + start;

  const backwardsFeatures = getFeaturesForSlantsBetweenDistances({
    isMovingForward: false,
    calculationStartPoint: clockwiseOutlineEdgePoint,
    featuresStart: clockwiseStartDistance,
    solidColorEnd: start,
    furthestRectangleEnd: fadeOutProperties ? fadeOutProperties.location : end,
    hasPointedTip: !fadeOutProperties,
    bearing,
    centerToEdgeWidth,
    slantAngle,
    featureGap: centerToEdgeWidth,
    color,
    opacity,
    fadeResultProperties: fadeInProperties,
    zoom,
  });

  features.push(...backwardsFeatures);

  return features;
};

/**
 * Returns the color and opacity of a feature at a location within a fade
 */
export const getColorAndOpacityFromLocationWithinFade = (
  fadeStart: FeatureFadeProperties,
  fadeEnd: FeatureFadeProperties,
  location: number,
  zoom: number,
) => {
  const gradientSteps = getStepCountFromZoomLevel(zoom);
  const fractionThroughFade = (location - fadeStart.location) / (fadeEnd.location - fadeStart.location);
  const currentStep = Math.floor(fractionThroughFade * gradientSteps);

  return {
    color: getMixedColor(fadeStart.color, fadeEnd.color, currentStep, gradientSteps),
    opacity: getMixedOpacity(fadeStart.opacity, fadeEnd.opacity, currentStep, gradientSteps),
  };
};

/**
 * Returns the GeoJSON chevron features that represent an uncalibrated incident
 */
export const getStackedChevronFeatures = ({
  calculationStartPoint,
  color,
  zoom,
  opacity,
  bearing,
  strokeColor,
  strokeOpacity,
}: StackedChevronFeatureProps) => {
  const features = [];
  const centerToEdgeWidth = getIncidentBarCenterToEdgeWidth(zoom);
  let featureCount = 0;

  for (
    let chevronStart = 0;
    chevronStart < getLengthOfAllChevrons(centerToEdgeWidth);
    chevronStart += centerToEdgeWidth, featureCount++
  ) {
    features.push(
      ...getChevronFeatures({
        calculationStartPoint,
        start: chevronStart,
        color,
        opacity,
        zoom,
        bearing,
        strokeColor,
        strokeOpacity,
      }),
    );
  }

  return features;
};

/**
 * The distance to the tip of the last chevron in the uncalibrated incident bar
 */
export const getLengthOfAllChevrons = (width: number): number => {
  const chevronsInMaxLength = Math.ceil(INCIDENT_BAR_UNCALIBRATED_LENGTH / width);
  const distanceToMaxLengthTip = Math.floor(chevronsInMaxLength * width);
  const distanceToMaxCountTip = Math.floor(MAX_UNCALIBRATED_CHEVRONS_COUNT * width);

  return Math.min(distanceToMaxCountTip, distanceToMaxLengthTip);
};

/**
 * Returns an opacity from the current step, out of a total number of steps that equally mixes the
 * start and end opacities between the total number of steps
 */
export const getMixedOpacity = (startOpacity: number, endOpacity: number, currentStep: number, totalSteps: number) => {
  return ((totalSteps - currentStep) * startOpacity) / totalSteps + (currentStep * endOpacity) / totalSteps;
};

/**
 * Returns a color from the current step, out of a total number of steps that equally mixes the
 * start and end colors between the total number of steps
 * @note this trivial implementation ignores color theory and mixing relative color lightness
 * and would preform poorly under more complex use cases
 */
export const getMixedColor = (startColor: string, endColor: string, currentStep: number, totalSteps: number) => {
  const startColorR = startColor.substring(1, 3);
  const startColorG = startColor.substring(3, 5);
  const startColorB = startColor.substring(5, 7);
  const endColorR = endColor.substring(1, 3);
  const endColorG = endColor.substring(3, 5);
  const endColorB = endColor.substring(5, 7);

  const decimalRed =
    ((totalSteps - currentStep) * parseInt(startColorR, 16)) / totalSteps +
    (currentStep * parseInt(endColorR, 16)) / totalSteps;
  const hexRed = getTwoDigitHexString(decimalRed);

  const decimalGreen =
    ((totalSteps - currentStep) * parseInt(startColorG, 16)) / totalSteps +
    (currentStep * parseInt(endColorG, 16)) / totalSteps;
  const hexGreen = getTwoDigitHexString(decimalGreen);

  const decimalBlue =
    ((totalSteps - currentStep) * parseInt(startColorB, 16)) / totalSteps +
    (currentStep * parseInt(endColorB, 16)) / totalSteps;
  const hexBlue = getTwoDigitHexString(decimalBlue);

  return `#${hexRed}${hexGreen}${hexBlue}`;
};

export function getIncidentCameraCones(incident: Incident, mapZoom: number, stationsMap: StationsMap): MapSourceData {
  const features = incident.cameras
    .filter((c) => !!stationsMap[c?.id])
    .map((incidentCamera) => {
      const station = stationsMap[incidentCamera?.id];
      const areaPolygonPoints = getPolygonPointsLatLng({ incidentCamera, station });
      const shapes = [basePolygon(areaPolygonPoints, 0.3)];

      if (hasLngLat(station)) {
        const rectPoints = getRectPointsLatLng(incident, station, incidentCamera, mapZoom);
        const rect = basePolygon(rectPoints, 0.3);
        shapes.push(rect);

        const uncertaintyPolygonPoints = getPolygonPointsLatLng({
          incidentCamera,
          station,
          coneAngle: CAMERA_UNCERTAINTY_ANGLE,
        });
        const uncertaintyCone = basePolygon(uncertaintyPolygonPoints, 0.5);
        shapes.push(uncertaintyCone);
      }

      return shapes;
    });

  return {
    type: 'FeatureCollection',
    features: _flatten(features),
  };
}

// TODO: Remove this function after completion of
// https://panoai.atlassian.net/browse/RD-5411 + https://panoai.atlassian.net/browse/RD-5409
export function getIncidentBarFeatures(
  incidents: Incident[],
  /** the id of the selected incident, used for styling */
  highlightedIncidentId: number,
  stationsMap: StationsMap,
  /** map zoom level, used to determine the width of the feature */
  mapZoom: number,
): Record<string, MapSourceData> {
  const groupedData: Record<string, MapSourceData> = {};

  const incidentsGroupedByLabel = _groupBy(incidents, getIncidentLabel);

  _forEach(incidentsGroupedByLabel, (labeledIncidents, label) => {
    const features = labeledIncidents.map((incident) => {
      // If no currentIncident, highlight all; otherwise, highlight the currentIncident
      const highlight: boolean = !highlightedIncidentId || incident.id === highlightedIncidentId;

      return incident.cameras
        .filter((c) => c.mark)
        .filter((c) => !!stationsMap[c?.id])
        .map((incidentCamera) => {
          const station = stationsMap[incidentCamera?.id];
          const rectPoints = getRectPointsLatLng(incident, station, incidentCamera, mapZoom);
          const rect = basePolygon(rectPoints, highlight ? 0.3 : 0.25);

          if (!highlight) {
            return [rect];
          }

          const uncertaintyPolygonPoints = getPolygonPointsLatLng({
            incidentCamera,
            station,
            coneAngle: getCameraUncertaintyAngleByMapZoom(mapZoom),
          });
          const uncertaintyCone = basePolygon(uncertaintyPolygonPoints, highlight ? 1 : 0.5);

          return [rect, uncertaintyCone];
        });
    });

    groupedData[label.toLowerCase()] = {
      type: 'FeatureCollection',
      features: _flatten(_flatten(features)),
    };
  });

  return groupedData;
}

/**
 * Returns a set of CoordinatesForDisplay objects for a given set of coordinates (lat/lon)
 * This is used for the coordinates display and dropdown
 */
export const getCoordinatesForDisplay = (
  coordinates: LonLat,
  coordinateSystem: CoordinateSystem,
): CoordinatesForDisplay[] => {
  const lonLat = { lon: coordinates?.lon, lat: coordinates?.lat };

  if (!hasLngLat(lonLat)) {
    return null;
  } else if (coordinateSystem === CoordinateSystem.GDA2020) {
    return [
      {
        coordinateSystem: CoordinateSystem.GDA2020,
        coordinateString: stringifyGDACoordinates(getGDACoordinatesFromLonLat(lonLat)),
      },
      {
        coordinateString: stringifyLonLat(lonLat),
        coordinateSystem: CoordinateSystem.WGS84,
      },
    ];
  }

  return [
    {
      coordinateString: stringifyLonLat(lonLat),
      coordinateSystem: CoordinateSystem.WGS84,
    },
  ];
};

export const stringifyGDACoordinates = (gda: GDACoordinates) => {
  return `E:\u00A0${gda.easting}, N:\u00A0${gda.northing}, Z:\u00A0${gda.zone}`;
};

export const stringifyLonLat = (lonLat: LonLat): string => {
  return `${lonLat.lat.toFixed(5)}, ${lonLat.lon.toFixed(5)}`;
};

export const lngLatForApi = (lonLat: LonLat): LonLat => {
  return _mapValues(lonLat, (v) => _round(v, MAX_LNGLAT_SCALE));
};

export const isLonLat = (str: string): boolean => {
  const lonLat = _compact(str.split(/[, ]/));
  const isLonLatRange = (n: number): boolean => n >= -180 && n <= 180;

  return lonLat.length === 2 && _every(lonLat.map(Number), isLonLatRange);
};

export const reverseLonLat = (str: string): string => _compact(str.split(/[, ]/)).map(_trim).reverse().join(', ');

// Convert distance of two points from coordinate values to millimeter (approximate)
export const getMilliMeterDistanceFromLonLat = (loc1: LonLat, loc2: LonLat, zoom: number): number => {
  const dis = Math.pow(loc2.lat - loc1.lat, 2) + Math.pow(loc2.lon - loc1.lon, 2);

  return Math.sqrt(dis) * Math.pow(2, zoom - 1);
};

// The diameter of incident/station icon is 10mm.
// If center point of two incidents has distance less than MAX_OVERLAPPING_IN_MM (mm), we think they overlap, then do not plot overlapping ones.
const MAX_OVERLAPPING_IN_MM = 5;
export const keepNonOverlappingPoints = (points: Incident[], zoom: number): Incident[][] => {
  const nonOverlapping: Incident[][] = [];
  for (let i = 0; i < points.length; i++) {
    const point: Incident = points[i];
    if (hasNoLngLat(point)) {
      continue;
    }

    if (nonOverlapping.length === 0) {
      nonOverlapping.push([point]);
    } else {
      let overlapped = false;
      for (let j = 0; j < nonOverlapping.length; j++) {
        const pointsIn: Incident[] = nonOverlapping[j];
        const dis: number = getMilliMeterDistanceFromLonLat(point as LonLat, _head(pointsIn) as LonLat, zoom);
        if (dis <= MAX_OVERLAPPING_IN_MM) {
          overlapped = true;
          pointsIn.push(point);
          break;
        }
      }

      if (!overlapped) {
        nonOverlapping.push([point]);
      }
    }
  }

  return nonOverlapping;
};
